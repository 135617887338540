import { useEffect, useReducer, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

function AddEditMaterial({ edit }) {
  const [values, setValues] = useState(null);
  const { materialID, lectureID } = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched", values });

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditMaterial": {
        return {
          ...state,
          submitAddEditMaterial: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }

  const [state, dispatch] = useReducer(reducer, {
    submitAddEditMaterial: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_MATERIAL_API
    : process.env.REACT_APP_ADMIN_ADD_MATERIAL_API;
  //!--------- add edit Material -------

  const [MaterialAddEditSuccess, MaterialAddEditErrors, submitLoading] =
    useAxios(
      api,
      "POST",
      state.submitAddEditMaterial.flag,
      state.submitAddEditMaterial.dependency,
      state.submitAddEditMaterial.data,
      true
    );

  //!--------- get the Material info for editing -------

  const [MaterialInfo, MaterialInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_MATERIAL_INFO_API}${materialID}`,
    "GET",
    materialID,
    materialID
  );
  useEffect(() => {
    if (MaterialInfo) {
      setValues({
        ...MaterialInfo.data,
        is_public: MaterialInfo.data.is_public === 1 ? true : false,
      });
    }
  }, [MaterialInfo]);

  useEffect(() => {
    //!---add actions ----

    if (MaterialAddEditSuccess) {
      navigate(-1);
      reset();
    }
  }, [MaterialAddEditSuccess]);

  const onSubmit = (data) => {
    const finalData = lectureID
      ? {
          id: lectureID,
          is_public: data?.is_public === true ? 1 : 0,
          address: data?.address[0],
        }
      : materialID
        ? {
            id: materialID,
            is_public: data?.is_public === true ? 1 : 0,
            address: data?.address[0] || null,
          }
        : "";
    dispatch({
      type: "setSubmitAddEditMaterial",
      payload: {
        flag: "AddEditMaterial",
        dependency: !state.submitAddEditMaterial.dependency,
        data: finalData,
      },
    });
  };

  return (
    <section className="flex h-auto w-full flex-col  items-center dark:bg-dark dark:text-light ">
      <HelmetTags title="Materials |  Dr Ahmed Gamal"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {materialID
            ? " Update Materials Data"
            : "Please fill out the information to add the file"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="md:max-w-3/4 flex  w-3/4  flex-col items-start justify-center gap-10 rounded-3xl bg-blue-200 p-16 shadow-lg shadow-blue-200/50"
        >
          {/** file is-public filed */}
          <div className="flex w-full items-center justify-between gap-16 md:flex-col md:gap-10 ">
            {/** file  */}
            <div className="flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label className="w-full text-start " htmlFor="address">
                Add File
              </label>

              <input
                id="address"
                className="signin-inputs   w-full"
                type="file"
                accept=".pdf,.doc, .docx"
                name="address"
                {...register("address", {
                  required: values ? false : true,
                  validate: (value) => !(value[0]?.size > 15 * 1024 * 1024),
                })}
              />

              {errors.address && (
                <p className="mt-2 w-full text-end text-[12px] text-blue-900">
                  {errors.address.type === "required" && "Please Add File"}
                  {errors.address.type === "validate" &&
                    "Maximum file size is 15 MB"}
                </p>
              )}
              {
                //!-------server errors -----

                MaterialAddEditErrors &&
                  MaterialAddEditErrors?.response?.data?.errors?.address && (
                    <p className="w-full text-end text-[12px] text-blue-900  ">
                      {
                        MaterialAddEditErrors?.response?.data?.errors
                          ?.address[0]
                      }
                    </p>
                  )
              }
            </div>
            <div className=" is_public flex w-1/2 flex-col items-end justify-center gap-2 md:w-full ">
              <div className="flex w-full items-center justify-end gap-10">
                <label className="visibility-switch">
                  <input
                    className=""
                    id="is_public"
                    name="is_public"
                    {...register("is_public", {
                      required: false,
                    })}
                    type="checkbox"
                  />
                  <span className="visibility-slider">
                    <svg
                      className="slider-icon"
                      viewBox="0 0 32 32"
                      xmlns="http://www.w3.org/2000/svg"
                      aria-hidden="true"
                      role="presentation"
                    >
                      <path fill="none" d="m4 16.5 8 8 16-16"></path>
                    </svg>
                  </span>
                </label>
                <div>
                  <label
                    className="w-full cursor-pointer truncate"
                    htmlFor="is_public"
                  >
                    Free
                  </label>
                </div>
              </div>

              {errors.is_public && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.is_public.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {
                //!-------server errors -----

                MaterialAddEditErrors &&
                  MaterialAddEditErrors?.response?.data?.errors?.is_public && (
                    <p className="w-full text-end text-[12px] text-blue-900  ">
                      {
                        MaterialAddEditErrors?.response?.data?.errors
                          ?.is_public[0]
                      }
                    </p>
                  )
              }
            </div>
          </div>

          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditMaterial.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditMaterial.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>
                {materialID ? "Confirm modification of file data" : "Add File"}
              </p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
}

export default AddEditMaterial;
