import { useEffect, useReducer, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

const AdminAddEditExam = ({ edit }) => {
  const [values, setValues] = useState(null);
  const [lectureData, setLectureData] = useState([]);
  const { ID } = useParams();

  function reducer(state, action) {
    switch (action.type) {
      case "setSubmitAddEditExam": {
        return {
          ...state,
          submitAddEditExam: action.payload,
        };
      }

      default:
        throw Error("Unknown action: " + action.type);
    }
  }
  const [state, dispatch] = useReducer(reducer, {
    submitAddEditExam: {
      flag: "",
      dependency: false,
      data: {},
    },
  });

  let api = edit
    ? process.env.REACT_APP_ADMIN_EDIT_TRIAL_EXAM_API
    : process.env.REACT_APP_ADMIN_ADD_TRIAL_EXAM_API;

  //!--------- add edit exam -------
  const [ExamAddEditSuccess, ExamAddEditErrors, submitLoading] = useAxios(
    api,
    "POST",
    state.submitAddEditExam.flag,
    state.submitAddEditExam.dependency,
    state.submitAddEditExam.data,
    true
  );

  // get all lectures in exam
  const [allExamsLecturesData, allExamsLecturesErrors] = useAxios(
    process.env.REACT_APP_ADMIN_GET_ALL_TRIAL_EXAMS_LECTURES_API,
    "GET",
    "GET",
    ""
  );

  //!--------- get the exam info for editing -------

  const [ExamInfo, ExamInfoErrors] = useAxios(
    `${process.env.REACT_APP_ADMIN_TRIAL_EXAM_INFO_API}/${ID}`,
    "GET",
    ID,
    ID
  );
  useEffect(() => {
    if (ExamInfo) {
      let temp = ExamInfo.data;
      delete temp.img;
      setValues({
        ...temp,
        visibility: temp.visibility === 1 ? true : false,
        nextlecture: temp.nextlecture === null ? 0 : temp.nextlecture,
      });
    }
  }, [ExamInfo]);
  useEffect(() => {
    if (allExamsLecturesData) {
      setLectureData(allExamsLecturesData?.data);
    }
  }, [allExamsLecturesData]);

  useEffect(() => {
    if (ExamAddEditSuccess) {
      navigate(-1);
      reset();
    }
    //! make the dependency false again to listen to the next try to submit
  }, [ExamAddEditSuccess]);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
    watch,
  } = useForm({ mode: "onTouched", values });
  const stageWatch = watch("stage");

  const onSubmit = (data) => {
    const finalData = ID
      ? {
          ...data,
          id: ID,
          img: data?.img[0] || null,
          visibility: +data?.visibility,
          v_model: +data?.v_model,
          is_buying: +data?.is_buying,
        }
      : {
          ...data,
          img: data?.img[0] || null,
        };
    dispatch({
      type: "setSubmitAddEditExam",
      payload: {
        flag: "AddEditLecture",
        dependency: !state.submitAddEditExam.dependency,
        data: finalData,
      },
    });
  };
  return (
    <section className="flex h-auto w-full flex-col  items-center  dark:bg-dark dark:text-light">
      <HelmetTags title="Exams | Mr.Ahmed Gamal"></HelmetTags>
      <div className="form-container my-20 flex w-full flex-col items-center">
        <h2 className="my-20 text-center text-3xl font-semibold">
          {ID ? "Edit Exam" : "Please fill out the information to add the test"}
        </h2>

        <form
          encType="multipart/form-data"
          method="post"
          onSubmit={handleSubmit(onSubmit)}
          className="flex h-fit  w-3/4 md:w-full flex-col  items-center justify-start gap-6 rounded-3xl bg-blue-200 p-10 shadow-lg shadow-red-200/30 md:w-[250px] "
        >
          {/** Name price filed */}
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className="name flex w-1/2 flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="name">Name</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="name"
                placeholder="Name"
                name="name"
                autoComplete="on"
                {...register("name", {
                  required: true,
                  //pattern: /^[A-Za-z]+$/,
                  maxLength: 40,
                  minLength: 3,
                })}
              />
              {errors.name && (
                <p className="w-full text-end  text-xl text-blue-900  ">
                  {errors.name.type === "required" &&
                    "Please fill out this field"}
                  {errors.name.type === "pattern" &&
                    "Only English letters are allowed"}
                  {errors.name.type === "maxLength" &&
                    "The maximum number of characters is 40 characters"}
                  {errors.name.type === "minLength" &&
                    "Please write at least 3 characters"}
                </p>
              )}

              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.name && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.name[0]}
                    </p>
                  )
              }
            </div>
            <div className=" price flex w-1/2 flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="price">
                Exam Price
              </label>
              <input
                className="signin-inputs w-full "
                type="number"
                min={0}
                id="price"
                placeholder="000"
                name="price"
                autoComplete="on"
                {...register("price", {
                  required: true,
                  valueAsNumber: true,
                })}
              />

              {errors.price && (
                <p className=" text-xl text-blue-900 ">
                  {errors.price.type === "required" &&
                    "Please fill out this field"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.price && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.price[0]}
                    </p>
                  )
              }
            </div>
          </div>

          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            {/* select lecture */}
            <div className="w-1/2 md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="nextlecture">Link Exam to a lecture</label>
              <select
                name="nextlecture"
                id="nextlecture"
                {...register("nextlecture", { required: false })}
              >
                <option className="opacity-50 bg-white text-black" value={0}>
                  No Link
                </option>
                {lectureData
                  .filter((lecture) => lecture.stage === +stageWatch)
                  .map((lecture) => (
                    <option value={lecture.key}>{lecture.name}</option>
                  ))}
              </select>

              {errors.lecture && (
                <p className=" text-xl text-blue-900 ">
                  {errors.lecture.type === "required" &&
                    "Please fill out this field"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.lecture && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.lecture[0]}
                    </p>
                  )
              }
            </div>
            {/* select stage */}
            <div className="w-1/2 md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="title">Stage</label>
              <select
                name="stage"
                id="stage"
                {...register("stage", { required: true })}
              >
                {/* <option value="1">1</option> */}
                <option value="2">2</option>
                <option value="3">3</option>
              </select>

              {errors.stage && (
                <p className=" text-xl text-blue-900 ">
                  {errors.stage.type === "required" &&
                    "Please fill out this field"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.stage && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
          </div>
          <div className="flex w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            {/* success_rate */}
            <div className="w-1/2 md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="success_rate">Success Rate</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="success_rate"
                placeholder="Success Rate"
                name="success_rate"
                autoComplete="on"
                {...register("success_rate", {
                  required: true,
                  minLength: 1,
                })}
              />
              {errors.success_rate && (
                <p className="w-full text-end  text-xl text-blue-900  ">
                  {errors.success_rate.type === "required" &&
                    "Please fill out this field"}
                  {errors.success_rate.type === "minLength" &&
                    "Please type at least 1 character"}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.stage && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.stage[0]}
                    </p>
                  )
              }
            </div>
            {/* duration */}
            <div className="w-1/2 md:w-full flex w-full flex-col items-start justify-center  gap-2  md:w-full">
              <label htmlFor="duration">Duration</label>
              <input
                className="signin-inputs w-full pl-4    "
                type="text"
                id="duration"
                placeholder="Duration"
                name="duration"
                autoComplete="on"
                {...register("duration", {
                  required: true,
                  minLength: 1,
                })}
              />
              {errors.duration && (
                <p className="w-full text-end  text-xl text-blue-900  ">
                  {errors.duration.type === "required" &&
                    "Please fill out this field"}
                  {errors.duration.type === "minLength" &&
                    "Please type at least 1 character"}
                </p>
              )}

              {errors.lecture && (
                <p className=" text-xl text-blue-900 ">
                  {errors.lecture.type === "required" &&
                    "Please fill out this field"}{" "}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.lecture && (
                    <p className="w-full text-end  text-xl text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.lecture[0]}
                    </p>
                  )
              }
            </div>
          </div>
          {/** Points filed */}
          {/* <div className="flex  w-full items-start justify-between gap-16 md:flex-col md:gap-10 ">
            <div className=" price flex w-full flex-col items-end justify-center gap-2 md:w-full">
              <label className="w-full truncate" htmlFor="points">
                Points
              </label>
              <input
                className="signin-inputs w-full "
                type="number"
                min={0}
                id="points"
                placeholder="000"
                name="points"
                autoComplete="on"
                {...register("points", {
                  required: true,
                  valueAsNumber: true,
                  min: {
                    value: 0,
                    message: "Please enter a number greater than zero.",
                  },
                  validate: (value) =>
                    value >= 0 || "Please enter a number greater than zero.",
                })}
              />

              {errors.points && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.points.type === "required" &&
                    "Please fill out this field"}
                </p>
              )}
              {errors.points && (
                <p className="text-[12px] text-blue-900 ">
                  {errors.points.type === "min" &&
                    "Please Add Only Positive Number"}
                </p>
              )}
              {
                //!-------server errors -----

                ExamAddEditErrors &&
                  ExamAddEditErrors?.response?.data?.errors?.points && (
                    <p className="w-full  text-[12px] text-blue-900  ">
                      {ExamAddEditErrors?.response?.data?.errors?.points[0]}
                    </p>
                  )
              }
            </div>
          </div> */}
          {/** visibility homework quiz fields */}
          {edit && (
            <div className="flex  w-full items-  gap-16 md:flex-col md:gap-10 ">
              <div className=" visibility flex w-1/3 md:w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="is_buying"
                      name="is_buying"
                      {...register("is_buying", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="is_buying"
                    >
                      Enable Buying
                    </label>
                  </div>
                </div>

                {errors.is_buying && (
                  <p className=" text-xl text-blue-900 ">
                    {errors.is_buying.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.is_buying && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {
                          ExamAddEditErrors?.response?.data?.errors
                            ?.is_buying[0]
                        }
                      </p>
                    )
                }
              </div>
              <div className=" visibility flex w-1/3  md:w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="v_model"
                      name="v_model"
                      {...register("v_model", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="v_model"
                    >
                      Show Answer{" "}
                    </label>
                  </div>
                </div>

                {errors.v_model && (
                  <p className=" text-xl text-blue-900 ">
                    {errors.v_model.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.v_model && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {ExamAddEditErrors?.response?.data?.errors?.v_model[0]}
                      </p>
                    )
                }
              </div>
              <div className=" visibility flex w-1/3  md:w-full flex-col items-end justify-center gap-2 md:w-full">
                <div className="flex w-full items-center justify-end gap-10">
                  <label className="visibility-switch">
                    <input
                      className=""
                      id="visibility"
                      name="visibility"
                      {...register("visibility", {
                        required: false,
                      })}
                      type="checkbox"
                    />
                    <span className="visibility-slider">
                      <svg
                        className="slider-icon"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        role="presentation"
                      >
                        <path fill="none" d="m4 16.5 8 8 16-16"></path>
                      </svg>
                    </span>
                  </label>
                  <div>
                    <label
                      className="w-full cursor-pointer truncate"
                      htmlFor="visibility"
                    >
                      Visibility{" "}
                    </label>
                  </div>
                </div>

                {errors.visibility && (
                  <p className=" text-xl text-blue-900 ">
                    {errors.visibility.type === "required" &&
                      "Please fill out this field"}{" "}
                  </p>
                )}
                {
                  //!-------server errors -----

                  ExamAddEditErrors &&
                    ExamAddEditErrors?.response?.data?.errors?.visibility && (
                      <p className="w-full text-end  text-xl text-blue-900  ">
                        {
                          ExamAddEditErrors?.response?.data?.errors
                            ?.visibility[0]
                        }
                      </p>
                    )
                }
              </div>
            </div>
          )}

          {/** Lecture Image  */}
          <div className="flex w-full flex-col items-end gap-2">
            <label className="w-full " htmlFor="img">
              Upload Exam image
            </label>

            <input
              id="img"
              className="signin-inputs w-full pl-4"
              type="file"
              accept="image/jpg, image/jpeg, image/png, image/webp"
              name="img"
              {...register("img", {
                required: values ? false : true,
                validate: (value) => !(value[0]?.size > 5000000),
              })}
            />

            {errors.img && (
              <p className="mt-2 w-full text-end  text-xl text-blue-900">
                {errors.img.type === "required" &&
                  "Please add a photo of the Exam"}
                {errors.img.type === "validate" && "Maximum image size is 5MB"}
              </p>
            )}
            {
              //!-------server errors -----

              ExamAddEditErrors &&
                ExamAddEditErrors?.response?.data?.errors?.img && (
                  <p className="w-full text-end  text-xl text-blue-900  ">
                    {ExamAddEditErrors?.response?.data?.errors?.img[0]}
                  </p>
                )
            }
          </div>

          <div className=" flex w-full flex-col items-end justify-center gap-2 ">
            <label className="w-full truncate " htmlFor="description">
              Description{" "}
            </label>
            <textarea
              className=" resize-none"
              {...register("description", {
                maxLength: 155,
                minLength: 3,
              })}
              name="description"
              id="description"
              cols="40"
              rows="5"
            ></textarea>
            {errors.description && (
              <p className=" text-xl text-blue-900 ">
                {errors.description.type === "maxLength" &&
                  "The maximum number of characters is 155 characters"}
                {errors.description.type === "minLength" &&
                  "Please write at least 3 letters"}
              </p>
            )}
            {
              //!-------server errors -----

              ExamAddEditErrors &&
                ExamAddEditErrors?.response?.data?.errors?.description && (
                  <p className="w-full text-end  text-xl text-blue-900  ">
                    {ExamAddEditErrors?.response?.data?.errors?.description[0]}
                  </p>
                )
            }
          </div>
          {/** submit */}

          <button
            disabled={
              !isValid || (submitLoading && state.submitAddEditExam.flag)
            }
            className=" submit mt-6 w-full "
            type="submit"
          >
            {submitLoading && state.submitAddEditExam.flag ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <p>{ID ? "Confirm Edit Exam" : "Add Exam"}</p>
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default AdminAddEditExam;
