import RevisionImage from "../../../assets/Revision.jpg";

const FreeSession = () => {
  return (
    <section id="Free-Session" data-scene className="width h-fit  my-32 pb-16">
      {/* <h2
        data-aos="fade-up-right"
        className="text-7xl font-bold md:text-center md:text-4xl"
      >
        Free Session: Revision Chapter one.
      </h2> */}

      {/* <div
        data-aos="flip-down"
        className="w-3/4 mx-auto  h-fit   my-28 flex justify-center"
      >
        <div className="w-fit h-full overflow-hidden border-2 border-cyan-500 p-4">
          <img
            className="w-full h-full  object-contain cursor-pointer duration-300 hover:scale-110  "
            src={RevisionImage}
            alt=""
          />
        </div>
      </div> */}

      <div data-aos="zoom-in-up" className="flex justify-center w-full">
        <p className="text-center text-4xl w-3/4">
          This is the best time to start . You are stronger than you can ever
          imagine , you are responsible for your success ; We can only show you
          the right path to take
        </p>
      </div>
    </section>
  );
};

export default FreeSession;
