import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useParams } from "react-router-dom";

const BlockedStudents = () => {
  const { studentID } = useParams();

  const [allBlockedStudentsData, allBlockedStudentsErrors, loading] = useAxios(
    process.env.REACT_APP_GET_BLOCKED_STUDENT_API,
    "GET",
    "GET",
    true
  );

  const table = useTable(
    "BlockedStudents",
    "",
    "",
    "",
    "",
    "",
    "",
    true,
    allBlockedStudentsData?.data
  );
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Blocked Students | Dr Ahmed Gamal"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">
          Blocked Students
        </h2>
      </div>

      <div className="h-fit  w-full">{table}</div>
    </section>
  );
};

export default BlockedStudents;
