import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import { useForm } from "react-hook-form";

const Invitations = () => {
  const [formValues, setFormValues] = useState({});
  const [formFlag, setFormFlag] = useState();
  const [formDependency, setFormDependency] = useState(false);
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [
    SendInvitationsSuccess,
    SendInvitationsErrors,
    SendInvitationsLoading,
  ] = useAxios(
    process.env.REACT_APP_SEND_INVITATIONS_STUDENT_API,
    "POST",
    formFlag,
    formDependency,
    formValues
  );

  const onSubmit = (formData) => {
    setFormValues(formData);
    setFormFlag("recharge");
    setFormDependency(true);
  };

  useEffect(() => {
    if (SendInvitationsSuccess) {
      toast.success("Sent Successfully..");
      setFormFlag("");
      setFormDependency(false);
    }
  }, [SendInvitationsSuccess]);

  useEffect(() => {
    if (SendInvitationsErrors) {
      toast.error(SendInvitationsErrors);
      setFormFlag("");
      setFormDependency(false);
    }
  }, [SendInvitationsErrors]);
  return (
    <section className="h-auto width flex flex-col items-center">
      <div className="h-auto width flex flex-col items-center my-20">
        <h4 className="text-3xl font-bold">Send Invitations</h4>
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="flex flex-col justify-center items-start  bg-white p-16  rounded-3xl shadow-lg shadow-blue/50 w-3/4 my-6 gap-6"
        >
          {/** code  */}

          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2 ">
              <label htmlFor="mobile">Mobile</label>
              <input
                id="mobile"
                className="signin-inputs w-full"
                type="text"
                placeholder="mobile"
                name="mobile"
                autoComplete="on"
                {...register("mobile", {
                  required: true,
                  pattern: /^[\d]{11}/,
                  maxLength: 11,
                })}
              />
              {errors.mobile && (
                <p className="mt-2 text-xs text-blue-500">
                  {errors.mobile.type === "required" &&
                    "Please, fill this input"}
                  {errors.mobile.type === "pattern" &&
                    "Enter your 11-digit phone number"}
                  {errors.mobile.type === "maxLength" &&
                    "Enter your 11-digit phone number"}
                </p>
              )}
              {/* Server errors for mobile number */}
              {SendInvitationsErrors &&
                SendInvitationsErrors?.response?.data?.errors?.mobile && (
                  <p className="text-xs text-blue-500">
                    {SendInvitationsErrors?.response?.data?.errors?.mobile[0]}
                  </p>
                )}
            </div>
          </div>

          <button
            className=" submit mt-6 w-full "
            type="submit"
            disabled={SendInvitationsLoading}
          >
            {SendInvitationsLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Send"
            )}
          </button>
        </form>
      </div>
    </section>
  );
};

export default Invitations;
