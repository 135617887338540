import { Link } from "react-router-dom";
import Lecture1 from "../../../assets/lectures/lec1.jpg";
import Lecture2 from "../../../assets/lectures/lec2.jpg";
import Lecture3 from "../../../assets/lectures/lec3.jpg";
import Lecture4 from "../../../assets/lectures/lec4.jpg";
import Lecture5 from "../../../assets/lectures/lec5.jpg";
import Lecture6 from "../../../assets/lectures/lec6.jpg";
import Slider from "react-slick";

function Lectures() {
  const settings = {
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 680,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
    ],
  };

  return (
    <section id="Lectures" data-scene className="width h-fit my-32 py-20">
      <h2 data-aos="fade-up-right" className="text-7xl font-bold">
        Best Lectures
      </h2>

      <Slider
        {...settings}
        className="slider-lectures mt-28 flex justify-between gap-10 items-center"
      >
        {[Lecture1, Lecture2, Lecture3, Lecture4, Lecture5, Lecture6].map(
          (lecture, index) => (
            <div
              key={index}
              className="slide mx-10 w-[350px] h-[340px] relative"
            >
              <div className="w-full h-[270px] border-2 border-cyan-600 p-4">
                <img
                  className="w-full h-full object-cover"
                  src={lecture}
                  alt={`Lecture ${index + 1}`}
                />
              </div>

              <h3 className="title text-3xl text-center mt-10">
                Final Revision Exams (3)
              </h3>
              <h4 className="stage bg-cyan-500 p-4 top-5 left-5 absolute font-bold font-Oswald">
                STAGE 2
              </h4>

              <div className="lecture-content bg-rd-200 absolute w-full h-4/5 top-0 left-0">
                <div className="w-full h-full relative">
                  <h5 className="price bg-pink-500 w-fit p-4 text-end top-5 right-5 absolute font-Oswald">
                    80 EGP
                  </h5>
                  <h4 className="session bg-pink-500 w-fit p-4 text-end -bottom-2 left-5 absolute font-Oswald">
                    Session 6
                  </h4>
                  <Link
                    to="/register"
                    className="stage bg-cyan-500 p-4 -bottom-2 right-5 absolute font-bold font-Oswald duration-300 hover:bg-pink-500"
                  >
                    JOIN NOW
                  </Link>
                </div>
              </div>
            </div>
          )
        )}
      </Slider>
    </section>
  );
}

export default Lectures;
