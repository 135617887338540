import HelmetTags from "../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-regular-svg-icons";
import { useAuthDispatch } from "../../../MainComponents/GlobalContext.jsx";
import axios from "axios";
import { toast } from "react-hot-toast";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import logoLight from "../../../assets/logo-light.png";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";

function SignIn({ admin }) {
  const AuthDispatch = useAuthDispatch();
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [hide, setHide] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [forgetPassowrdLoading, setForgetPassowrdLoading] = useState(false);
  const [serverErrors, setServerErrors] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
  });

  const handleSignIn = (data) => {
    setSubmitLoading(true);
    async function postData() {
      try {
        let res = await axios.post(
          admin
            ? process.env.REACT_APP_ADMIN_LOGIN_API
            : process.env.REACT_APP_LOGIN_API,
          data,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );
        let userData = await res.data;
        setSubmitLoading(false);
        localStorage.setItem("userData", JSON.stringify(userData));
        AuthDispatch({ type: "setUserData", payload: userData });

        // set axios
        axios.defaults.headers.common["Authorization"] = JSON.parse(
          localStorage.userData
        ).student_token;

        toast.success(userData?.message);
        reset();
        navigate(`${admin ? "/admin/dashboard" : "/home"}`, { replace: true });
      } catch (error) {
        setSubmitLoading(false);
        setServerErrors(error?.response?.data?.error);
        toast.error(
          error?.response?.data?.error || "An error occurred, please try again."
        );
      }
    }
    postData();
  };

  const handleForgotPassword = (data) => {
    setForgetPassowrdLoading(true);
    //!  if success
    setForgotPassword(false);
    setForgetPassowrdLoading(false);
    //!  if error
    setForgetPassowrdLoading(false);
    setServerErrors();

    reset();
  };

  return (
    <section className="flex h-fit min-h-screen flex-col items-center justify-center gap-24 py-10 dark:bg-dark sm:py-14">
      <HelmetTags
        title="Log in | Dr Ahmed Gamal"
        description="Log in | Dr Ahmed Gamal"
        index
      >
        <link rel="canonical" href="https://ahmedgamal.online/signin" />
      </HelmetTags>

      <div
        //!welcome-message
        className="welcome-message  flex flex-col items-center  justify-center"
      >
        <Link className="h-52 w-auto text-4xl " to="/">
          <img className="h-full " src={logoLight} alt="logo" />
        </Link>
        <h1 className="mb-5 mt-10 font-bold ">Log in | Dr Ahmed Gamal</h1>
        <h2 className="opacity-80">
          Welcome back! Please enter your information
        </h2>
      </div>

      <div className="sign-in-form flex w-full flex-col items-center ">
        <form
          //!form
          onSubmit={handleSubmit(handleSignIn)}
          method="post"
          className="sm:max-w-3/4 flex w-[450px] max-w-[450px]  flex-col items-start  justify-center rounded-3xl bg-white  p-16 shadow-3xl  sm:w-3/4"
        >
          {!admin && (
            <div className="w-full  md:w-full">
              <div className="  flex w-full flex-col items-start justify-center gap-2">
                <label
                  className="text-start flex items-center gap-2"
                  htmlFor="mobile"
                >
                  Student number{" "}
                  <span className="text-red-700 text-3xl font-bold">*</span>
                </label>
                <div className="relative w-full">
                  <input
                    className="signin-inputs pl-4   w-full "
                    type="number"
                    id="mobile"
                    placeholder="Student number"
                    name="mobile"
                    autoComplete="off"
                    {...register("mobile", {
                      required: true,
                      pattern: /^[\d]{11}/,
                      maxLength: 11,
                    })}
                  />
                  <FontAwesomeIcon
                    className="absolute bottom-1/2 right-4 h-6  w-7 translate-y-1/2"
                    icon={faWhatsapp}
                  />
                </div>
              </div>
              {errors.mobile && (
                <p className="mt-2 w-full text-start text-[12px] text-blue-900">
                  {errors.mobile.type === "required" &&
                    "Please fill out this field"}
                  {errors.mobile.type === "pattern" &&
                    "Please enter an 11-digit phone number"}
                  {errors.mobile.type === "maxLength" &&
                    "Please enter an 11-digit phone number"}
                </p>
              )}
            </div>
          )}

          {admin && (
            <div className="flex w-full flex-col items-start justify-center gap-2 md:order-1 md:w-full">
              <label htmlFor="username">User name</label>
              <input
                className="signin-inputs w-full"
                type="text"
                id="username"
                placeholder="User name"
                name="username"
                autoComplete="on"
                {...register("username", {
                  required: true,
                  pattern: /^[A-Za-z0-9-_]*$/,
                  maxLength: 20,
                  minLength: 3,
                })}
              />
              {/* Username validation errors */}
              {errors.username && (
                <p className="text-xl text-blue-500">
                  {errors.username.type === "required" &&
                    "Please, fill out this field."}
                  {errors.username.type === "pattern" && "Only English allowed"}
                  {errors.username.type === "maxLength" &&
                    "Maximum characters is 20"}
                  {errors.username.type === "minLength" &&
                    "Please type at least 3 letters"}
                </p>
              )}
              {/* Server errors for username */}
              {serverErrors &&
                serverErrors?.response?.data?.errors?.username && (
                  <p className="text-xs text-blue-500">
                    {serverErrors?.response?.data?.errors?.username[0]}
                  </p>
                )}
            </div>
          )}

          {/**
           * //!Password filed
           *  */}

          <div className=" mb-4 mt-10 flex w-full flex-col items-end justify-center gap-2">
            <label htmlFor="password">Password</label>
            <div
              //!input password relative
              className="relative-hide relative h-auto w-full"
            >
              <input
                id="password"
                className="signin-inputs max-w-60 "
                type={`${hide ? "text" : "password"}`}
                placeholder="Password"
                autoComplete="on"
                name="password"
                {...register("password", {
                  required: true,
                  maxLength: 25,
                  minLength: 6,
                })}
              />
              <div
                //!eye icons
                onClick={() => setHide(!hide)}
                className="hide-pass absolute bottom-1/2 right-4 flex  h-5 w-7 translate-y-1/2  cursor-pointer "
              >
                {hide ? (
                  <FontAwesomeIcon className="h-full w-full" icon={faEye} />
                ) : (
                  <FontAwesomeIcon
                    className="h-full w-full"
                    icon={faEyeSlash}
                  />
                )}
              </div>
            </div>
            {errors.password && (
              <p className="text-[12px] text-blue-900 ">
                {errors.password.type === "required" &&
                  "Please enter your password"}
                {errors.password.type === "maxLength" &&
                  "The maximum number of characters is 25 characters"}
                {errors.password.type === "minLength" &&
                  "The minimum number of letters is 6 letters"}
              </p>
            )}

            {
              //!--- server errors --------
              serverErrors && (
                <p className="text-[12px] text-blue-900 ">{serverErrors}</p>
              )
            }
          </div>
          <div className="flex w-full justify-end  ">
            <Link to="/forget-password">
              <span className="w-fit cursor-pointer text-[13px] underline">
                Forgot your password?
              </span>
            </Link>
          </div>

          <button
            disabled={!isValid || submitLoading}
            className=" submit max-w-60 mt-10 "
            type="submit"
          >
            {submitLoading ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              "Sign in"
            )}
          </button>
        </form>
        {!admin && (
          <div className="my-10 w-full text-center text-[14px] ">
            Don't have an account?
            <Link className="font-bold" to="/register">
              Create account now
            </Link>
          </div>
        )}
      </div>
    </section>
  );
}

export default SignIn;
