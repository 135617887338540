import WhatsappImage from "../../../assets/whatsapp.svg";
import FacebookImage from "../../../assets/facebook.svg";
import InstagramImage from "../../../assets/instagram.svg";
import YoutubeImage from "../../../assets/youtube.svg";

const Footer = () => {
  return (
    <div>
      <footer id="Contact" data-scene class="bg-cyan-500 h-fit">
        <div
          data-aos="fade-up-right"
          data-aos-offset="0"
          class="footer-container width "
        >
          <div class="footer-container-above flex justify-between gap-10 items-center py-16  md:flex-col ">
            <h3 class="text-4xl font-bold  text-center leading-relaxed">
              DR.Ahmed Gamal, 25 years Experience as a Chemistry Teacher.
            </h3>

            <div class="footer-socials flex justify-start items-center gap-10">
              <a
                href="https://api.whatsapp.com/send?phone=201022089849"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="w-12 hover:opacity-80 duration-300"
                  src={WhatsappImage}
                  alt=""
                />
              </a>

              <a
                href="https://www.facebook.com/DrAhmedGamal78"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="w-[45px] hover:opacity-80 duration-300"
                  src={FacebookImage}
                  alt=""
                />
              </a>

              <a
                href="https://www.instagram.com/Dr_AhmedGamal_Chemistry/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="w-12 hover:opacity-80 duration-300"
                  src={InstagramImage}
                  alt=""
                />
              </a>

              <a
                href="https://www.youtube.com/@Dr.ahmedgamal.chemistry/videos"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  class="w-16 hover:opacity-80 duration-300"
                  src={YoutubeImage}
                  alt=""
                />
              </a>
            </div>
          </div>
        </div>
      </footer>

      <div
        data-scene
        class="creator-for-aos bg-cyan-500 -mt-4 border-t-2 border-t-light-gray"
      >
        <p class="text-center py-16 ">
          {" "}
          © Dr.Ahmed Gamal, All Right Reserved. By{" "}
          <a
            class="text-cblue font-bold"
            href="https://api.whatsapp.com/send?phone=201124265941"
            target="_blank"
            rel="noreferrer"
          >
            Omar Fathy.
          </a>{" "}
        </p>
      </div>
    </div>
  );
};

export default Footer;
