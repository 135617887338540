import { motion } from "framer-motion";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesLeft,
  faUserGraduate,
} from "@fortawesome/free-solid-svg-icons";
import { Link, useNavigate } from "react-router-dom";
import { useAuthState } from "./GlobalContext.jsx";
import { Popconfirm } from "antd";
import { useEffect, useState } from "react";
import useAxios from "./Hooks/useAxios.jsx";

function SingleChapter({ chapter, admin, reFetch, setReFetch, notSigned }) {
  const AuthState = useAuthState();
  const navigate = useNavigate();
  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  //!--------- delete Chapter -------

  const [deleteChapterSuccess, deleteChapterErrors, deleteloading] = useAxios(
    process.env.REACT_APP_ADMIN_DELETE_CHAPTER_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  function handleDeleteChapter(id) {
    setSubmitDelete({
      flag: "deleteChapter",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteChapterSuccess) {
      setReFetch(!reFetch);
    }
  }, [deleteChapterSuccess]);

  return (
    <motion.div
      layout
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.5 }}
      className="course group relative h-[390px]  w-[350px] overflow-hidden rounded-md bg-white shadow-lg sm:w-[300px]"
    >
      <div className="img relative z-[0] h-full w-full  overflow-hidden rounded-md">
        <img
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? `/admin/lectures/chapters/${chapter.key}`
                    : `/lectures/chapters/${chapter.key}`
              }`
            )
          }
          className={`absolute h-full w-full rounded-md object-cover  opacity-80 duration-300 hover:scale-110`}
          src={chapter.img}
          alt={chapter.name}
        />
      </div>
      <div className="chapter-details pointer-events-none absolute bottom-0 right-0 z-20 mt-7 flex h-fit w-full flex-col items-end overflow-hidden bg-white/80 p-8 pb-0  transition-all duration-300 ease-in-out  ">
        <h3
          onClick={() =>
            navigate(
              `${
                notSigned
                  ? "/signin"
                  : admin
                    ? `/admin/lectures/chapters/${chapter.key}`
                    : `/lectures/chapters/${chapter.key}`
              }`
            )
          }
          className={`title pointer-events-auto mb-7 w-fit max-w-full cursor-pointer   text-end  text-2xl font-bold text-secondary `}
        >
          {chapter?.name?.substring(0, 33)}
        </h3>
        <p className="description text-start text-2xl font-semibold leading-relaxed text-secondary/70">
          {chapter?.description?.substring(0, 70)}...
        </p>

        <div
          className={`visibility-cta  mt-5 flex w-full border-t-2 border-secondary  ${
            admin ? "justify-between" : "justify-center"
          }  items-center  py-9 transition-all duration-300 ease-in-out   `}
        >
          {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.some((permission) =>
              permission.name.startsWith("lecture_visibility")
            )) &&
            admin && (
              <div className="visibility pointer-events-none  flex h-10 w-fit  items-center justify-center bg-secondary px-3 text-xl font-semibold text-light">
                {chapter.visibility === 0 ? "hidden" : "visible"}
              </div>
            )}
          {admin ? (
            <div className="admin-options flex w-full justify-end gap-14">
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_edit")
                )) && (
                <Link
                  to={`/admin/lectures/edit-chapter/${chapter.key}`}
                  className="pointer-events-auto w-24 cursor-pointer border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
                >
                  Edit
                </Link>
              )}
              {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_delete")
                )) && (
                <Popconfirm
                  okText="Confirm"
                  okType="danger"
                  cancelText="Cancel"
                  color="#fecaca"
                  //okButtonProps={{ loading: loading }}
                  onConfirm={() => handleDeleteChapter(chapter.key)}
                  title="Do you really want to delete the chapter?"
                >
                  <button className="pointer-events-auto w-24 cursor-pointer border-2 border-accent bg-accent px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-accent active:scale-90">
                    Delete
                  </button>
                </Popconfirm>
              )}
            </div>
          ) : (
            <Link
              to={notSigned ? "/signin" : `/lectures/chapters/${chapter.key}`}
              className="cta pointer-events-auto flex items-center justify-center gap-2 border-2 border-secondary  bg-secondary   px-3 py-1  text-light transition-all duration-300 ease-in-out hover:gap-4 active:scale-90 "
            >
              <FontAwesomeIcon className="h-6 w-6  " icon={faAnglesLeft} fade />
              View lectures
            </Link>
          )}
        </div>
      </div>
    </motion.div>
  );
}

export default SingleChapter;
