import { useEffect } from "react";
import useCountDown from "../../../../MainComponents/Hooks/useCountDown";

const SingleLectureCountDown = ({ expireDate, dispatch, state }) => {
  const { days, hours, minutes, seconds } = useCountDown(expireDate);

  useEffect(() => {
    if (
      days === "00" &&
      hours === "00" &&
      minutes === "00" &&
      seconds === "00"
    ) {
      dispatch({ type: "setRefetch", payload: !state.refetch });
    }
  }, [days, dispatch, hours, minutes, seconds, state.refetch]);

  return (
    <div
      className={`title-description border-2 border-secondary p-3 rounded-xl mt-5 w-3/4 md:w-full  text-start"`}
    >
      <p className="font-bold text-3xl mb-4">Expire After</p>
      <p className="course-description text-2xl">
        {days} Days | {hours} Hours | {minutes} Minutes | {seconds} Seconds
      </p>
    </div>
  );
};

export default SingleLectureCountDown;
