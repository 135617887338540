import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { Col, Row } from "antd";
import Loader from "../../../../MainComponents/Loader.jsx";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";

function AdminHome() {
  const [homeInfoData, homeInfoErrors, homeInfoLoading] = useAxios(
    process.env.REACT_APP_GET_HOME_INFO_API,
    "GET",
    "GET",
    ""
  );

  if (homeInfoLoading) {
    return <Loader />;
  }
  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 bg-mainBackground dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Home | Dr Ahmed Gamal"></HelmetTags>
      <h2 className="w-full text-4xl font-bold md:text-center my-12">
        Welcom, Ahmed Gamal
      </h2>

      <Row gutter={[30, 30]}>
        {/* <Col md={12}>
          <div className="bg-[#5050b2] text-white p-14 rounded-lg">
            <h4 className="mb-4 text-3xl">1st stage</h4>
            <p className="text-6xl font-bold">0</p>
          </div>
        </Col> */}
        <Col xs={24} md={12}>
          <div className="bg-[#7DA0FA] text-white p-14 rounded-lg">
            <h4 className="mb-4 text-3xl">2st stage</h4>
            <p className="text-6xl font-bold">
              {homeInfoData?.data.second_stage}
            </p>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="bg-[#F3797E] text-white p-14 rounded-lg">
            <h4 className="mb-4 text-3xl">3st stage</h4>
            <p className="text-6xl font-bold">
              {" "}
              {homeInfoData?.data.third_stage}
            </p>
          </div>
        </Col>
        <Col xs={24} md={12}>
          <div className="bg-[#7978E9] text-white p-14 rounded-lg">
            <h4 className="mb-4 text-3xl">Total</h4>
            <p className="text-6xl font-bold">
              {" "}
              {homeInfoData?.data.first_stage +
                homeInfoData?.data.second_stage +
                homeInfoData?.data.third_stage}
            </p>
          </div>
        </Col>
      </Row>
    </section>
  );
}

export default AdminHome;
