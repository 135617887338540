import { Link } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { useAuthState } from "../../../../MainComponents/GlobalContext.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";

function Assistants() {
  const AuthState = useAuthState();

  const table = useTable("assistants");
  return (
    <section className="h-auto w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="The Assistants | Dr Ahmed Gamal"></HelmetTags>
      <div className="mb-20 flex flex-col items-start gap-20">
        <h2 className="w-full text-4xl font-bold md:text-center">Assistants</h2>
        {(AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
          AuthState.userData?.admin?.permissions?.some((permission) =>
            permission.name.startsWith("user_add")
          )) && (
          <Link
            to="/admin/assistants/add-assistant"
            className="ml-4 rounded-xl border-2 border-secondary bg-secondary px-2 py-1 font-semibold text-light transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 flex items-center"
          >
            <FontAwesomeIcon className="mr-4" icon={faPlus} />
            Add assistant
          </Link>
        )}
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Assistants;
