import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useState } from "react";
import { Link } from "react-router-dom";

function Students({ type }) {
  const [academiclevel, setAcademiclevel] = useState("2");

  const url = `${academiclevel}/${type === "online" ? "1" : "0"}`;
  const tableFor = type === "online" ? "onlineStudents" : "centerStudents";

  const table = useTable(tableFor, "", "", url, "", url);

  return (
    <section className="h-full w-full overflow-y-auto px-20 py-20 bg-mainBackground dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title="Students | Dr Ahmed Gamal"></HelmetTags>
      <div className="mb-20 flex flex-col items-end gap-20">
        <div className="w-full flex items-center justify-between gap-4 sm:flex-col">
          <h2 className="w-full text-4xl font-bold md:text-center">Students</h2>
          <Link
            to={`${
              type === "online"
                ? "/admin/online-students/blocked-students"
                : "/admin/center-students/blocked-students"
            }`}
            className={`min-w-max border-2 border-accent bg-accent text-light  px-6 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-accent   active:scale-90`}
          >
            Blocked Students
          </Link>
        </div>
        <div className="flex w-full  gap-10 md:justify-center sm:flex-col-reverse sm:items-center">
          {/* <button
            onClick={() => {
              setAcademiclevel("1");
            }}
            className={` border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "1" && "bg-secondary text-light"
            }`}
          >
            First grade
          </button> */}

          <button
            onClick={() => {
              setAcademiclevel("2");
            }}
            className={` border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "2" && "bg-secondary text-light"
            }`}
          >
            Second grade
          </button>

          <button
            onClick={() => {
              setAcademiclevel("3");
            }}
            className={` border-2 border-secondary  px-2 py-1 font-semibold  transition-all duration-300 hover:bg-light hover:text-secondary   active:scale-90 ${
              academiclevel === "3" && "bg-secondary text-light"
            }`}
          >
            Third grade
          </button>
        </div>
      </div>
      {/**
       * //!---------Table-----
       */}
      <div className="h-fit  w-full">{table}</div>
    </section>
  );
}

export default Students;
