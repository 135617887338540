import { faClock } from "@fortawesome/free-regular-svg-icons";
import {
  faAddressCard,
  faBuildingUser,
  faChalkboardUser,
  faLocationDot,
  faMobileScreenButton,
  faPerson,
  faSchoolFlag,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { Col, Popconfirm, Row } from "antd";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { toast } from "react-hot-toast";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import {
  useAuthState,
  useAuthDispatch,
} from "../../../../MainComponents/GlobalContext";
import { Loader } from "../../../../MainComponents";
import { faStackpath } from "@fortawesome/free-brands-svg-icons";

function Requests() {
  const AuthState = useAuthState();
  const AuthDispatch = useAuthDispatch();

  const [submitDelete, setSubmitDelete] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [submitAccept, setSubmitAccept] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const [reFetch, setReFetch] = useState(false);

  //!--------- all Requests request -------

  const [allRequestsData, allRequestsErrors, loading] = useAxios(
    process.env.REACT_APP_ALL_REQUESTS_API,
    "GET",
    "GET",
    reFetch
  );
  //!--------- delete Request -------

  const [deleteRequestSuccess, deleteRequestErrors] = useAxios(
    process.env.REACT_APP_DELETE_REQUEST_API,
    "POST",
    submitDelete.flag,
    submitDelete.dependency,
    submitDelete.data,
    true
  );
  //!--------- accept Request -------

  const [acceptRequestSuccess, acceptRequestErrors] = useAxios(
    process.env.REACT_APP_ACCEPT_REQUEST_API,
    "POST",
    submitAccept.flag,
    submitAccept.dependency,
    submitAccept.data,
    true
  );
  useEffect(() => {
    //! refetch the data on success delete or accept request

    if (deleteRequestSuccess || acceptRequestSuccess) {
      setReFetch(!reFetch);
      AuthDispatch({
        type: "setRefetchIfAcceptOrDeleteRequest",
      });
    }
  }, [deleteRequestSuccess, acceptRequestSuccess]);

  function handleDeleteReq(id) {
    setSubmitDelete({
      flag: "deleteRequest",
      dependency: !submitDelete.dependency,
      data: { id: id },
    });
  }
  function handleAcceptReq(id) {
    setSubmitAccept({
      flag: "acceptRequest",
      dependency: !submitAccept.dependency,
      data: { id: id },
    });
  }
  if (loading) {
    return <Loader />;
  }
  return (
    <>
      <HelmetTags title="Requests | Dr Ahmed Gamal"></HelmetTags>

      <section className="h-fit w-full overflow-y-auto px-20 py-20 dark:bg-dark dark:text-light sm:px-10">
        <h2 className="mb-16 w-full  text-4xl font-bold md:text-center">
          Inactive students
        </h2>

        {allRequestsData?.data?.length === 0 ? (
          <p className="w-full text-center">No new requests</p>
        ) : allRequestsErrors ? (
          <p className="w-full text-center">{allRequestsErrors}</p>
        ) : (
          allRequestsData?.data?.map((std) => (
            <div
              key={std.key}
              //!single student
              className="mx-auto mb-20 flex flex-col h-fit w-5/6 items-center justify-between gap-12 border-2 border-secondary p-6 lg:w-full"
            >
              {/* <div
                //!std-right
                className="std-img flex h-full w-2/5 flex-col items-center justify-center gap-9 amd:w-full "
              >
                <div className="std-img bg-green h-96 w-96 overflow-hidden p-4">
                  <div className="h-full w-full object-cover object-top p-4">
                    <img
                      className="h-full w-full  border-2 border-secondary object-cover object-top "
                      src={std.profile_pic}
                      alt={std.name}
                    />
                  </div>
                </div> 
                <div className="name-stage flex items-center justify-center gap-4">
                  <h4 className=" min-w-fit text-center text-3xl font-semibold opacity-80">
                    Class {std.stage}
                  </h4>
                </div>
              </div> */}
              <div
                //!std-left
                className="std-details"
              >
                <div className="std-info  ">
                  <Row gutter={[20, 20]}>
                    <Col md={12} lg={8}>
                      <div className="flex justify-start">
                        <div className="w-8 min-w-[32px]  text-3xl ">
                          <FontAwesomeIcon className="" icon={faPerson} />
                        </div>
                        <h4 className=" text-[17px] font-semibold ">
                          Name:{" "}
                          <span className="text-[15px] opacity-80">
                            {std.name}
                          </span>
                        </h4>
                      </div>
                    </Col>
                    <Col md={12} lg={8}>
                      <div className="flex justify-start">
                        <div className="w-8 min-w-[32px]  text-3xl ">
                          <FontAwesomeIcon className="" icon={faStackpath} />
                        </div>
                        <h4 className=" text-[17px] font-semibold ">
                          Class:{" "}
                          <span className="text-[15px] opacity-80">
                            {std.stage}
                          </span>
                        </h4>
                      </div>
                    </Col>
                    <Col md={12} lg={8}>
                      <div className="flex justify-start">
                        <div className="w-8 min-w-[32px]  text-3xl ">
                          <FontAwesomeIcon
                            className=""
                            icon={faMobileScreenButton}
                          />
                        </div>
                        <h4 className=" text-[17px] font-semibold ">
                          Phone:{" "}
                          <span className="text-[15px] opacity-80">
                            {std.mobile}
                          </span>
                        </h4>
                      </div>
                    </Col>
                    <Col md={12} lg={8}>
                      <div className="flex justify-start">
                        <div className="w-8 min-w-[32px]  text-3xl ">
                          <FontAwesomeIcon
                            className=""
                            icon={faMobileScreenButton}
                          />
                        </div>
                        <h4 className=" text-[17px] font-semibold ">
                          Parent's phone 1:
                          <span className="text-[15px] opacity-80 xs:text-center">
                            {std.parent_mobile}
                          </span>
                        </h4>
                      </div>
                    </Col>
                    <Col md={12} lg={8}>
                      <div className="flex justify-start">
                        <div className="w-8 min-w-[32px]  text-3xl ">
                          <FontAwesomeIcon
                            className=""
                            icon={faMobileScreenButton}
                          />
                        </div>
                        <h4 className=" text-[17px] font-semibold ">
                          Parent’s phone 2:
                          <span className="text-[15px] opacity-80 xs:text-center">
                            {std.parent_mobile2}
                          </span>
                        </h4>
                      </div>
                    </Col>
                    <Col md={12} lg={8}>
                      <div className="flex justify-start">
                        <div className="w-8 min-w-[32px] text-3xl">
                          <FontAwesomeIcon className="" icon={faLocationDot} />
                        </div>
                        <div className=" text-[17px] font-semibold">
                          <h5 className="inline-block">City: </h5>
                          <span className="text-[15px] opacity-80">
                            {std.area}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col md={12} lg={8}>
                      <div className="flex justify-start">
                        <div className="w-8 min-w-[32px] text-3xl">
                          <FontAwesomeIcon
                            className=""
                            icon={
                              std.is_online === "Center"
                                ? faBuildingUser
                                : faChalkboardUser
                            }
                          />
                        </div>
                        <div className=" text-[17px] font-semibold ">
                          <h5 className="inline-block">Place: </h5>
                          <span className="text-[15px] opacity-80">
                            {std.is_online}
                          </span>
                        </div>
                      </div>
                    </Col>
                    {std.section && (
                      <Col md={12} lg={8}>
                        <div className="flex justify-start">
                          <div className=" text-[17px] font-semibold ">
                            <h5 className="inline-block">Department: </h5>
                            <span className="text-[15px] opacity-80">
                              {std.section}
                            </span>
                          </div>
                          <div className="w-8 min-w-[32px]   text-3xl ">
                            <FontAwesomeIcon
                              className=""
                              icon={faAddressCard}
                            />
                          </div>
                        </div>
                      </Col>
                    )}
                    <Col md={12} lg={8}>
                      <div className="flex justify-start">
                        <div className="w-8 min-w-[32px]   text-3xl ">
                          <FontAwesomeIcon className="" icon={faSchoolFlag} />
                        </div>
                        <div className=" text-[17px] font-semibold ">
                          <h5 className="inline-block">School: </h5>
                          <span className="text-[15px] opacity-80">
                            {std.school}
                          </span>
                        </div>
                      </div>
                    </Col>
                    <Col md={12} lg={8}>
                      <div className="flex justify-start">
                        <div className="w-8 min-w-[32px]  text-3xl ">
                          <FontAwesomeIcon className="" icon={faClock} />
                        </div>
                        <h4 className=" text-[17px] font-semibold ">
                          Release Date:
                          <span className="text-[15px] opacity-80">
                            {std.created_at}
                          </span>
                        </h4>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="btns flex items-center justify-center gap-7 sm:flex-row mt-10">
                  {(AuthState.userData?.admin?.roles[0]?.name ===
                    "superadmin" ||
                    AuthState.userData?.admin?.permissions?.some((permission) =>
                      permission.name.startsWith("request_accept")
                    )) && (
                    <button
                      onClick={() => handleAcceptReq(std.key)}
                      className="approve-btn w-28   border-2 border-save bg-save py-[5px] text-center text-2xl text-light transition-all duration-300 hover:scale-105 hover:bg-transparent hover:text-save active:scale-90"
                    >
                      Accept
                    </button>
                  )}
                  {(AuthState.userData?.admin?.roles[0]?.name ===
                    "superadmin" ||
                    AuthState.userData?.admin?.permissions?.some((permission) =>
                      permission.name.startsWith("request_delete")
                    )) && (
                    <Popconfirm
                      okText="Confirm"
                      okType="danger"
                      cancelText="Cancel"
                      color="#fecaca"
                      //okButtonProps={{ loading: loading }}
                      onConfirm={() => handleDeleteReq(std.key)}
                      title="Do you really want to delete the student's request?"
                    >
                      <button className="approve-btn w-28   border-2 border-accent bg-accent py-[5px] text-center text-2xl text-light transition-all duration-300 hover:scale-105 hover:bg-transparent  hover:text-accent active:scale-90">
                        Delete
                      </button>
                    </Popconfirm>
                  )}
                </div>
              </div>
            </div>
          ))
        )}
      </section>
    </>
  );
}

export default Requests;
