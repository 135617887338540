function Loader() {
  return (
    <div className="w-full h-screen flex items-center justify-center">
      <div class="loader">
        <div class="worm"></div>
        <div class="circleMiddle"></div>
      </div>
    </div>
  );
}

export default Loader;
