import { Link } from "react-router-dom";
import BurgerIcon from "../../../assets//burger.svg";
import CloseIcon from "../../../assets//close.svg";
import { useState } from "react";

export const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [parentIdOpen, setParentIdOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const toggleParentId = () => {
    setParentIdOpen(!parentIdOpen);
  };

  return (
    <header className="menu-container">
      <div
        className="menu-buttons inline-block w-40 h-36 bg-cyan-500 fixed top-0 left-0 z-50 rounded-br-full cursor-pointer"
        onClick={toggleMenu}
      >
        <img
          className={`burger w-24 absolute ${menuOpen ? "hide" : ""}`}
          src={BurgerIcon}
          alt="Menu"
        />
        <img
          className={`close w-24 absolute ${menuOpen ? "show" : ""}`}
          src={CloseIcon}
          alt="Close"
        />
      </div>
      <div
        className={`menu fixed top-0 left-0 w-1/2 lg:w-11/12 h-full bg-pink-500 rounded-br-full origin-top-left z-40 duration-500 transition-all ease-in-out ${
          menuOpen ? "scale" : ""
        }`}
      >
        <nav className="duration-500 h-full transition-all ease-in-out">
          <ul className="flex flex-col items-center h-full justify-start gap-16 pt-16 nav-items">
            <li>
              <a href="#Hero" onClick={toggleMenu}>
                Home
              </a>
            </li>
            <li>
              <a href="#Instructor" onClick={toggleMenu}>
                Instructor
              </a>
            </li>
            <li>
              <a href="#Lectures" onClick={toggleMenu}>
                Courses
              </a>
            </li>
            <li>
              <a href="#Contact" onClick={toggleMenu}>
                Contact
              </a>
            </li>
            <li>
              <Link to="/signin" onClick={toggleMenu}>
                Sign In
              </Link>
            </li>
          </ul>
        </nav>
      </div>
      {/* <div>
        <button className="parent-id-button" onClick={toggleParentId}>
          Parent ID
        </button>
        <div className={`fixed-parent-id ${parentIdOpen ? "scale" : ""}`}>
          <button className="hide-parent-id" onClick={toggleParentId}>
            Hide
          </button>
        </div>
      </div> */}
    </header>
  );
};
