/* eslint-disable import/first */
import { Suspense, lazy, useState, useEffect } from "react";
import LandinPage from "./Pages/LandingPage/LandingPage";
// eslint-disable-next-line import/first
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./SASS/styles.scss";

import { Layout, Loader, NotFound } from "./MainComponents";
import {
  SignIn,
  Register,
  StudentLectures,
  StudentChapter,
  StudentLecture,
  Exams,
  SingleExamPage,
  HomePage,
  StudentQuestionBank,
  QuizNew,
  StudentShowQuestionBank,
  Profile,
  Amount,
  Invitations,
} from "./Pages/Student";
import {
  Posts,
  AddEditPost,
  Students,
  EditStudent,
  Attendance,
  AdminLectures,
  AddEditChapter,
  AddEditLecture,
  Videos,
  AdminMaterials,
  AddEditMaterial,
  AddEditVideo,
  AdminChapter,
  AdminLecture,
  AdminQuiz,
  AdminExams,
  Groups,
  AddEditGroup,
  Codes,
  Requests,
  SessionStudents,
  SessionAbsence,
  Assistants,
  AddEditAssistant,
  QuestionBank,
  QuestionBankCategory,
  AddEditEssayQuestion,
  AddEditChooseQuestion,
  AddEditCategory,
  Whatsapp,
  ViewStudent,
  CodesTracker,
  AdminProfile,
  AdminAddEditExam,
  AdminViewExam,
  AdminAddStudentLecture,
  AdminHome,
  BlockedStudents,
} from "./Pages/Admin";

import ProtectedAdminRoutes from "./MainComponents/ProtectionComponents/ProtectedAdminRoutes";
import { useAuthState } from "./MainComponents/GlobalContext.jsx";
import ModalAnswer from "./Pages/Student/Lectures/modalAnswer/ModalAnswer";
import AdminModalAnswer from "./Pages/Admin/Dashboard/Exams/AdminModalAnswer.jsx";
import ForgetPassword from "./Pages/Student/SignIn/ForgetPassword.jsx";
import MainLayout from "./MainComponents/MainLayout.jsx";

function App() {
  const AuthState = useAuthState();

  const router = createBrowserRouter([
    //!--------Landing Page Layout--------
    {
      path: "/",
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/dashboard"
              : "/home"
          }`}
          isAllowed={!AuthState.userData}
        >
          <LandinPage />
        </ProtectedAdminRoutes>
      ),
    },
    //!Student Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/signin"
          isAllowed={AuthState.userData?.student_token}
        >
          <MainLayout />
        </ProtectedAdminRoutes>
      ),
      children: [
        {
          path: "/home",
          element: <HomePage />,
        },
        {
          path: "/profile",
          element: <Profile />,
        },
        {
          path: "/home/amount",
          element: <Amount />,
        },
        {
          path: "/home/invitations",
          element: <Invitations />,
        },
        {
          path: "/lectures",
          element: <StudentLectures />,
        },
        {
          path: "/lectures/chapters/:chapterID",
          element: <StudentChapter />,
        },
        {
          path: "/lectures/sessions/:lectureID",
          element: <StudentLecture />,
        },
        {
          path: "/lectures/sessions/quiz/modal-answer/:ID",
          element: <ModalAnswer quiz />,
        },
        {
          path: "/lectures/sessions/homework/modal-answer/:ID",
          element: <ModalAnswer homework />,
        },
        {
          path: "/exams",
          element: <Exams />,
        },
        {
          path: "/exams/:examID",
          element: <SingleExamPage />,
        },
        {
          path: "/exams/solve-exam/:ID",
          element: <QuizNew exam />,
        },
        {
          path: "/exams/modal-answer/:ID",
          element: <ModalAnswer exam />,
        },
        //!Quiz
        {
          path: "/home/quiz/:ID",
          element: <QuizNew />,
        },
        //!Homework
        {
          path: "/home/homework/:ID",
          element: <QuizNew homework />,
        },
        //! Student Question Bank
        {
          path: "/home/question-bank",
          element: <StudentQuestionBank />,
        },
        {
          path: "/home/question-bank/:ID",
          element: <StudentShowQuestionBank />,
        },
      ],
    },
    //!Admin Layout
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath="/not-found"
          isAllowed={
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
          }
        >
          <MainLayout admin />
        </ProtectedAdminRoutes>
      ),
      children: [
        //!--------- dashboard Routes --------------
        {
          path: "/admin/dashboard",
          element: (
            <ProtectedAdminRoutes redirectPath="/not-found" isAllowed={true}>
              <AdminHome />
            </ProtectedAdminRoutes>
          ),
        },
        {
          path: "/admin/help",
          element: (
            <ProtectedAdminRoutes redirectPath="/not-found" isAllowed={true}>
              <Posts />
            </ProtectedAdminRoutes>
          ),
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("post_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/help/edit-post/:postID",
              element: <AddEditPost edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("post_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/help/edit-post/:postID",
              element: <AddEditPost edit />,
            },
          ],
        },
        //!------ students Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/center-students",
              element: <Students type="center" />,
            },
            {
              path: "/admin/online-students",
              element: <Students type="online" />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/online-students/edit-student/:studentID",
              element: <EditStudent />,
            },
            {
              path: "/admin/center-students/edit-student/:studentID",
              element: <EditStudent />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("student_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/online-students/view-student/:studentID",
              element: <ViewStudent />,
            },
            {
              path: "/admin/center-students/view-student/:studentID",
              element: <ViewStudent />,
            },
            {
              path: "/admin/online-students/blocked-students",
              element: <BlockedStudents online />,
            },
            {
              path: "/admin/center-students/blocked-students",
              element: <BlockedStudents />,
            },
          ],
        },
        //!------ Attendance Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("attendance")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/attendance",
              element: <Attendance />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("attendance")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/attendance/:groupID/:lectureID",
              element: <SessionStudents />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("attendance")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/attendance/:groupID/:lectureID/absence",
              element: <SessionAbsence />,
            },
          ],
        },
        //!------ Lectures Routes --------------

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures",
              element: <AdminLectures />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/add-chapter",
              element: <AddEditChapter />,
            },
            {
              path: "/admin/lectures/add-lecture",
              element: <AddEditLecture />,
            },
            {
              path: "/admin/lectures/:chapterID/add-lecture",
              element: <AddEditLecture />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/edit-chapter/:chapterID",
              element: <AddEditChapter edit />,
            },
            {
              path: "/admin/lectures/edit-lecture/:lectureID",
              element: <AddEditLecture edit />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/chapters/:chapterID",
              element: <AdminChapter />,
            },
            {
              path: "/admin/lectures/sessions/:lectureID",
              element: <AdminLecture />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addstudent")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/add-student",
              element: <AdminAddStudentLecture />,
            },
          ],
        },
        //!--- lecture_addvideos ---
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addvideos")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/videos",
              element: <Videos />,
            },
            {
              path: "/admin/lectures/:lectureID/add-video",
              element: <AddEditVideo />,
            },
            {
              path: "/admin/videos/edit-video/:VideoID",
              element: <AddEditVideo edit />,
            },
          ],
        },
        //!--- lecture_addmatrial ---
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addmatrial")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/materials",
              element: <AdminMaterials />,
            },
            {
              path: "/admin/lectures/:lectureID/add-material",
              element: <AddEditMaterial />,
            },
          ],
        },
        //!--- lecture_quiz ---

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addquiz")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/quiz",
              element: <AdminQuiz />,
            },
            {
              path: "/admin/lectures/sessions/quiz/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit lectureQuestion />,
            },
            {
              path: "/admin/lectures/sessions/quiz/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit lectureQuestion />,
            },
          ],
        },
        //!--- lecture_homework ---

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("lecture_addHw")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/lectures/sessions/:lectureID/homework",
              element: <AdminQuiz homework />,
            },
            {
              path: "/admin/lectures/sessions/homework/edit-essay-question/:questionID",
              element: <AddEditEssayQuestion edit lectureQuestion />,
            },
            {
              path: "/admin/lectures/sessions/homework/edit-choose-question/:questionID",
              element: <AddEditChooseQuestion edit lectureQuestion />,
            },
          ],
        },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams",
              element: <AdminExams />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/add-exam",
              element: <AdminAddEditExam />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/edit-exam/:ID",
              element: <AdminAddEditExam edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_view")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/all-students/:examID",
              element: <AdminViewExam />,
            },
            {
              path: "/admin/exams/all-students/:examID/model-answer",
              element: <AdminModalAnswer />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("exam_addquestions")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/exams/:examID",
              element: <AdminQuiz exam />,
            },
          ],
        },
        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("questionbank")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/question-bank",
        //       element: <QuestionBank />,
        //     },
        //   ],
        // },
        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith(
        //             "questionbank_viewquestionbycategory"
        //           )
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/question-bank/:categoryID",
        //       element: <QuestionBankCategory />,
        //     },
        //   ],
        // },
        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("questionbank_addcategory")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/question-bank/add-category",
        //       element: <AddEditCategory />,
        //     },
        //   ],
        // },
        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("questionbank_addquestion")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/question-bank/:categoryID/add-essay-question",
        //       element: <AddEditEssayQuestion />,
        //     },
        //     {
        //       path: "/admin/question-bank/:categoryID/add-choose-question",
        //       element: <AddEditChooseQuestion />,
        //     },
        //   ],
        // },
        // {
        //   element: (
        //     <ProtectedAdminRoutes
        //       redirectPath="/not-found"
        //       isAllowed={
        //         AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
        //         AuthState.userData?.admin?.permissions?.some((permission) =>
        //           permission.name.startsWith("questionbank_editquestion")
        //         )
        //       }
        //     />
        //   ),
        //   children: [
        //     {
        //       path: "/admin/question-bank/:categoryID/edit-essay-question/:questionID",
        //       element: <AddEditEssayQuestion edit />,
        //     },
        //     {
        //       path: "/admin/question-bank/:categoryID/edit-choose-question/:questionID",
        //       element: <AddEditChooseQuestion edit />,
        //     },
        //   ],
        // },

        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("group")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/groups",
              element: <Groups />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("group_add")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/groups/add-group",
              element: <AddEditGroup />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("group_edit")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/groups/edit-group",
              element: <AddEditGroup />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/codes",
              element: <Codes />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("code_tracker")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/codes-tracker",
              element: <CodesTracker />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("request")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/requests",
              element: <Requests />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={true}
              // isAllowed={
              //   AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
              //   AuthState.userData?.admin?.permissions?.some((permission) =>
              //     permission.name.startsWith("request_accept")
              //   )
              // }
            />
          ),
          children: [
            {
              path: "/admin/profile",
              element: <AdminProfile />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("user")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/assistants",
              element: <Assistants />,
            },
            {
              path: "/admin/assistants/add-assistant",
              element: <AddEditAssistant />,
            },
            {
              path: "/admin/assistants/edit-assistant/:assistantID",
              element: <AddEditAssistant edit />,
            },
          ],
        },
        {
          element: (
            <ProtectedAdminRoutes
              redirectPath="/not-found"
              isAllowed={
                AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
                AuthState.userData?.admin?.permissions?.some((permission) =>
                  permission.name.startsWith("whatsapp_show")
                )
              }
            />
          ),
          children: [
            {
              path: "/admin/whatsapp",
              element: <Whatsapp />,
            },
          ],
        },
      ],
    },
    //!SignIn-routes
    {
      element: (
        <ProtectedAdminRoutes
          redirectPath={`${
            AuthState.userData?.admin?.roles[0]?.name === "superadmin" ||
            AuthState.userData?.admin?.permissions?.length > 0
              ? "/admin/dashboard"
              : "/lectures"
          }`}
          isAllowed={!AuthState.userData}
        />
      ),
      children: [
        //!SignIn

        {
          path: "/signin",
          element: <SignIn />,
        },
        {
          path: "/forget-password",
          element: <ForgetPassword />,
        },
        {
          path: "/admin/signin",
          element: <SignIn admin />,
        },
        //!Register
        {
          path: "/register",
          element: <Register />,
        },
      ],
    },

    //!NotFound
    {
      path: "/not-found",
      element: <NotFound />,
    },
    {
      path: "*",
      element: <NotFound />,
    },
  ]);
  return (
    <main className="dar:text-light text-secondary duration-100 dark:bg-dark">
      <RouterProvider router={router} fallbackElement={<Loader />} />
    </main>
  );
}

export default App;
