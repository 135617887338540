import WhatsappImage from "../../../assets/whatsapp.svg";
import FacebookImage from "../../../assets/facebook.svg";
import InstagramImage from "../../../assets/instagram.svg";
import YoutubeImage from "../../../assets/youtube.svg";
import OneImage from "../../../assets/one.jpg";
import TwoImage from "../../../assets/two.jpg";
import ThreeImage from "../../../assets/three.jpg";
import FourImage from "../../../assets/four.jpg";
import FiveImage from "../../../assets/five.jpg";
import SixImage from "../../../assets/six.jpg";
import SevenImage from "../../../assets/seven.jpg";
import EightImage from "../../../assets/eight.jpg";
import NineImage from "../../../assets/nine.jpg";
import TenImage from "../../../assets/ten.jpg";
import ElevenImage from "../../../assets/eleven.jpg";
import TwelveImage from "../../../assets/twelve.jpg";
import ThertenImage from "../../../assets/therten.jpg";
import LogoImage from "../../../assets/logo.png";
import { useEffect } from "react";
import { gsap } from "gsap";
import Slider from "react-slick";
import { Link } from "react-router-dom";

function Hero() {
  useEffect(() => {
    gsap.from(".under-svg > *", {
      duration: 1,
      opacity: 0,
      delay: 9,
      stagger: 0.3,
      x: "-70px",
    });

    gsap.from(".logo", {
      duration: 1,
      opacity: 0,
      delay: 9,
      y: "-70px",
    });

    gsap.from(".hero-slider-wrapper", {
      duration: 1,
      opacity: 0,
      delay: 11,
      y: "70px",
    });

    gsap.from(".menu-buttons", {
      backgroundColor: "#06b6d4",
      duration: 1,
      opacity: 0,
      delay: 10,
      x: -150,
      y: -150,
    });

    gsap.to(".menu-buttons", {
      backgroundColor: "#ec4899",
      duration: 1,
      delay: 10,
    });

    // gsap.from(".right", {
    //   duration: 2,
    //   y: 0,
    //   opacity: 1,
    //   stagger: 0.4,
    //   delay: 8,
    // });

    gsap.from(".stripe1 img", {
      duration: 2,
      y: 200,
      opacity: 0,
      stagger: 0.4,
      delay: 6,
    });
    gsap.to(".stripe1 img", {
      duration: 2,
      y: 200,
      opacity: 1,
      // stagger: 0.4,
      delay: 2,
    });

    gsap.from(".stripe2 img", {
      duration: 2,
      y: -10,
      // opacity: 0,
      stagger: 0.4,
      delay: 4,
    });
    gsap.to(".stripe2 img", {
      duration: 2,
      y: 200,
      opacity: 1,
      // stagger: 0.4,
      delay: 2,
    });
  }, []);

  const sliderSettings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 1500,
    autoplay: true,
    autoplaySpeed: 2000,
    arrows: false,
  };

  return (
    <section
      id="Hero"
      data-scene
      className="hero-wrapper min-h-screen h-fit w-full flex justify-between gap-10 bg-cyan-500"
    >
      <div
        className="left bg-cyan-500 hero-text md:w-full min-h-screen"
        data-aos="fade-right"
      >
        <div className="logo-wrapper flex w-full justify-center mt-40 h-fit">
          <img className="logo w-72" src={LogoImage} alt="Logo" />
        </div>

        <div className="svg-wrapper-fixed">
          <svg>
            <symbol id="text-copy">
              <text text-anchor="middle" x="50%" y="50%" class="text--line">
                DR. Ahmed Gamal
              </text>
            </symbol>
            <g class="g-ants">
              <use xlinkHref="#text-copy" class="text-copy" id="outline"></use>
              <use xlinkHref="#text-copy" class="text-copy" id="fill"></use>
            </g>
          </svg>
        </div>

        <div className="under-svg flex flex-col gap-10 justify-start h-full px-20 w-full -mt-24 md:-mt-10 md:text-center items-center">
          <p className="text-4xl font-bold font-Oswald text-center text-white">
            Clinical pharmacist <br />
            Master degree in organic chemistry <br />
            25 Years Experience as a chemistry teacher for secondary.
          </p>

          <div className="hero-buttons flex justify-start items-center gap-10 my-16">
            <Link
              to="/signin"
              className="call-button relative p-4 duration-300 font-Oswald text-3xl border-2 z-10 w-fit overflow-hidden hover:text-cyan-500 text-light-gray border-light-gray"
            >
              Sign In
            </Link>

            <Link
              to="/register"
              className="relative parent-id-button p-4 duration-300 bg-light-gray text-cyan-500 hover:text-light-gray font-Oswald text-3xl border-2 overflow-hidden z-10 w-fit border-light-gray"
            >
              Register
            </Link>
          </div>
          <div className="hero-socials flex justify-start items-center gap-10">
            <a
              href="https://api.whatsapp.com/send?phone=201022089849"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 hover:opacity-80 duration-300"
                src={WhatsappImage}
                alt="WhatsApp"
              />
            </a>
            <a
              href="https://www.facebook.com/DrAhmedGamal78"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-[45px] hover:opacity-80 duration-300"
                src={FacebookImage}
                alt="Facebook"
              />
            </a>
            <a
              href="https://www.instagram.com/Dr_AhmedGamal_Chemistry/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-12 hover:opacity-80 duration-300"
                src={InstagramImage}
                alt="Instagram"
              />
            </a>
            <a
              href="https://www.youtube.com/@Dr.ahmedgamal.chemistry/videos"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="w-16 hover:opacity-80 duration-300"
                src={YoutubeImage}
                alt="YouTube"
              />
            </a>
          </div>
        </div>

        <div className="hero-slider-wrapper hidden asm:block w-5/6 mx-auto max-h-[350px] my-20">
          <div className="hero-slider w-full h-full">
            <Slider {...sliderSettings}>
              {[
                OneImage,
                TwoImage,
                ThreeImage,
                FourImage,
                FiveImage,
                SixImage,
                SevenImage,
              ].map((image, index) => (
                <div
                  key={index}
                  className="w-full max-h-[350px] mx-10 flex justify-center items-center"
                  data-aos="fade-up"
                >
                  <img
                    src={image}
                    alt={`Slide ${index + 1}`}
                    className="w-full max-h-full object-cover object-top"
                  />
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>

      <div
        className="right w-1/2 max-h-screen hero-img overflow-y-hidden flex justify-around px-12 gap-12 md:hidden"
        data-aos="fade-left"
      >
        <div className="img-stripe stripe1 w-1/2 flex flex-col items-center gap-12 py-12 lg:w-3/4">
          {[OneImage, TwoImage, ThreeImage, FourImage, FiveImage, SixImage].map(
            (image, index) => (
              <img
                key={index}
                className="w-full min-h-[350px] max-h-[350px] object-cover object-top"
                src={image}
                alt=""
                data-aos="fade-up"
              />
            )
          )}
        </div>

        <div className="img-stripe stripe2 w-1/2 flex flex-col items-center gap-12 py-12 lg:hidden">
          {[
            SevenImage,
            EightImage,
            NineImage,
            TenImage,
            ElevenImage,
            TwelveImage,
            ThertenImage,
          ].map((image, index) => (
            <img
              key={index}
              className="w-full min-h-[350px] max-h-[350px] object-cover object-top"
              src={image}
              alt=""
              data-aos="fade-up"
            />
          ))}
        </div>
      </div>
    </section>
  );
}

export default Hero;
