import { useEffect } from "react";
import HelmetTags from "../../MainComponents/HelmetTags";
import AboutInstructor from "./Components/AboutInstructor";
import Footer from "./Components/Footer";
import FreeSession from "./Components/FreeSession";
import { Header } from "./Components/Header";
import Hero from "./Components/Hero";
import Lectures from "./Components/Lectures";
import AOS from "aos";
import "../../SASS/landingPage.scss";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function LandingPage() {
  // const [allRandomCourses, setAllRandomCourses] = useState([]);
  // async function getRandomCourses() {
  //   try {
  //     let res = await axios(
  //       process.env.REACT_APP_LANDING_PAGE_RANDOM_LECTURES_API
  //     );
  //     let randomCourses = await res.data;
  //     if (randomCourses?.n >= 3) {
  //       setAllRandomCourses(randomCourses);
  //       AuthDispatch({ type: "setIsChapters", payload: true });
  //     } else {
  //       AuthDispatch({ type: "setIsChapters", payload: false });
  //     }
  //   } catch (error) {
  //     // console.log(error);
  //   }
  // }
  // useEffect(() => {
  //   getRandomCourses();
  // }, []);

  useEffect(() => {
    AOS.init({
      duration: 1500,
    });
  }, []);

  return (
    <div className="landingPage">
      <HelmetTags title="Dr Ahmed Gamal" index>
        <link rel="canonical" href="https://ahmedgamal.online" />
      </HelmetTags>

      <Header />
      <Hero />
      <AboutInstructor />
      <Lectures />
      <FreeSession />
      <Footer />
    </div>
  );
}

export default LandingPage;
