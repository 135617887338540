import TeacherImage from "../../../assets/teacher.png";
import FeatureOne from "../../../assets/png1.png";
import FeatureThree from "../../../assets/png3.png";

const AboutInstructor = () => {
  return (
    <section
      id="Instructor"
      data-scene
      className="h-fit width mt-60 flex flex-col gap-60  "
    >
      {/* <div className="feature1 w-full h-[500px]  lg:h-[450px] asm:h-screen   flex asm:flex-col justify-between items-center gap-28 asm:gap-16 ">
        <div
          data-aos="fade-up-right"
          className="feature1__img h-full asm:h-1/2 w-1/2 asm:w-full border-b-4  border-b-cyan-500 flex items-end justify-center "
        >
          <img
            className=" w-full h-full object-contain object-bottom"
            src={TeacherImage}
            alt=""
          />
        </div>

        <div
          data-aos="fade-up-left"
          className="feature1__text asm:items-center  px-5 w-1/2 asm:w-full bg-cyan-500 flex flex-col justify-center asm:text-center  h-full asm:h-1/2 "
        >
          <h2 className="text-9xl lg:text-6xl asm:text-4xl asm:text-center asm:-ml-0  -ml-40 font-bold">
            Best Chemistry Teacher
          </h2>

          <p className="font-Oswald  text-6xl  lg:text-5xl  asm:text-3xl  my-16 asm:my-10">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Incidunt
            minus quae maxime.
          </p>

          <a
            className="font-Oswald font-bold  feature-cta border-2 border-pink-500 relative z-10 overflow-hidden tracking-widest asm:text-xl   hover:text-pink-500 duration-300 bg-pink-500 w-fit p-4"
            href="#Lectures"
          >
            DISCOVER LECTURES
          </a>
        </div>
      </div> */}

      <div className="feature2 w-full h-[500px] lg:h-[450px] asm:h-screen  flex-row-reverse flex asm:flex-col  justify-between items-center gap-28 asm:gap-16">
        <div
          data-aos="fade-up-left"
          className="feature2__img h-full asm:h-1/2 w-1/2 asm:w-full  border-b-4 border-b-cyan-500 flex justify-center items-end"
        >
          <img
            className="w-full h-full object-contain object-bottom"
            src={FeatureOne}
            alt=""
          />
        </div>

        <div
          data-aos="fade-up-right"
          className="feature2__text asm:items-center px-5 w-1/2 asm:w-full  bg-cyan-500 flex flex-col justify-center items-end text-end h-full asm:h-1/2 asm:text-center"
        >
          <h2 className="text-9xl lg:text-6xl asm:text-4xl asm:text-center asm:-mr-0 -mr-40 font-bold">
            3rd secondary
          </h2>
          <p className="font-Oswald  text-6xl lg:text-5xl  asm:text-3xl  my-16 asm:my-10 text-left">
            Chemistry chapters : <br />
            Chapter 1 : Transition elements
            <br />
            chapter 2 : Chemical analysis
            <br />
            chapter 3 : Chemical equilibrium
            <br />
            chapter 4 : Electrochemistry
            <br />
            chapter 5 : Organic chemistry
          </p>
        </div>
      </div>

      <div className="feature3 w-full h-[500px] lg:h-[450px] asm:h-screen  flex asm:flex-col justify-between items-center gap-28 asm:gap-16">
        <div
          data-aos="fade-up-right"
          className="feature3__img h-full asm:h-1/2 w-1/2 asm:w-full  border-b-4  border-b-cyan-500 flex items-end justify-center "
        >
          <img
            className="w-full h-full object-contain object-bottom"
            src={FeatureThree}
            alt=""
          />
        </div>
        <div
          data-aos="fade-up-left"
          className="feature3__text asm:items-center px-5 w-1/2 asm:w-full  bg-cyan-500 flex flex-col justify-center  h-full asm:text-center asm:h-1/2"
        >
          <h2 className="text-9xl lg:text-6xl asm:text-4xl asm:text-center asm:-ml-0 -ml-40 font-bold">
            2nd secondary
          </h2>
          <p className="font-Oswald  text-6xl lg:text-5xl  asm:text-3xl  my-16 asm:my-10">
            Chemistry Chapters : <br />
            (First Term)
            <br />
            Chapter 1 :Atomic Structure
            <br />
            Chapter 2:The periodic table & Classification of Elements
            <br /> <br /> (Second Term)
            <br />
            Chapter 3..Bonds & Forms of Molecules
            <br /> Chapter 4..Representative Elements
          </p>
        </div>
      </div>
    </section>
  );
};

export default AboutInstructor;
